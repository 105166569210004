
.body-augmented{
    background-image: url("./resources/augmented_bg.png");
    width:100%;
    height:100vh;
    color:#fff;
    text-align: center;
    display: block;
    
    
}
.body-what-is-great{
    width:100%;
    color:#000;
    text-align: center;
    
    
}
.body-augmented h1{
    line-height: 1.5;
    text-align:center;
    justify-content: center;
    display: inline-block;
    vertical-align: middle;
}
.body-augmented h3{
        line-height: 1.5;
    text-align:center;
    justify-content: center;
    display: inline-block;
    vertical-align: middle;
}
.WhatIsGreat-box{
    /* Rectangle 512 */

width: 100%;
background: #FFFFFF;
box-shadow: 4px 8px 30px 5px rgba(92, 134, 167, 0.25);
border-radius: 10px;
color:#000;
padding: 5px;
margin: 5px;
}
.WhatIsGreat-box-inline{

 width: 100%;
}
.WhatIsGreat-box-inline h3{
    text-align: left;
    margin:10px;
    vertical-align: super;

}
.WhatIsGreat-box-inline div{
    text-align: left;
    margin:2.5%;
    vertical-align: super;
    width:95%;

}
.great-slides{
    display:none;
    text-align: center;
    /* Our Service Stack */
    font-family: 'Red Hat Display';

    font-style: normal;
    font-weight: 700;
/* identical to box height, or 60px */


    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

}
#great-slides{
width: 100%;

}
.body-amazing-team{
    width: 90%;
    display: flex;
    flex-direction: column;

}
.great-slides-div-pane{

    width:100%;
    
    }
.body-talent{
    /* Rectangle 519 */
width: 100%;
text-align: center;
background: rgba(217, 217, 217, 0.2);
/* Leverage World-Class Talent */


/* identical to box height */




}
.body-understand-employees{
    /* Rectangle 519 */
width: 100%;
text-align: center;
margin: 0 auto;
display: flex;
background: rgba(217, 217, 217, 0.2);
flex-direction: column;

}
.inter-heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
}
.body-talent h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
/* Leverage World-Class Talent */



}
.body-contact{
    background:linear-gradient(180deg, #18476C 50%, #fff 50%);
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.input-box{
    /* Rectangle 529 */

box-sizing: border-box;

background: #FFFFFF;
border: 1px solid #CFCFCF;
border-radius: 5px;
/* Rectangle 529 */

box-sizing: border-box;
padding-left: 10px;
padding-right: 10px;

height: 50px;


background: #FFFFFF;
border: 1px solid #CFCFCF;
border-radius: 5px;


}
@media only screen and (min-width:700px) {
    .body-understand-employees{
        /* Rectangle 519 */
    width: 100%;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    background: rgba(217, 217, 217, 0.2);
    
    }
    .WhatIsGreat-box-inline{
        display: inline-flex;
    flex-direction: row;
     width: 100%;
    }

    .body-amazing-team{
        width: 100%;
        display: inline-flex;
        flex-direction: row;
    
    }
    .body-contact{
        background:linear-gradient(180deg, #18476C 50%, #fff 50%);
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        color: #fff;
        display: flex;
        flex-direction: row;
    }

}
