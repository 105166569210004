@import url(https://unpkg.com/swiper/swiper-bundle.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.body-display{
    position:relative;
    display:flex;
    flex-direction: column-reverse;
    position:relative;
    top:10vh;
    width:100%;
    
    height: 140vh;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    align-items: center;
    
}

.body-display .div-50{
    margin-top:0vh;
}
.body-service{
    position: relative;
    height:auto;
    padding-top: 80px;
    background: #F9F9F9;
    padding-bottom: 50px;
}
/* Our Service Stack */



.display-heading-text-h1{
    font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 4.625em;
line-height: 125%;
/* or 92px */

color: #007BB6;
}

.our-service-heading-text{
    /* Lorem Ipsum is simply dummy text of the printing */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 2em;
line-height: 125%;
/* or 45px */

color: #000000;



}
.our-service-para-text{

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.625em;
    line-height: 125%;
    /* or 25px */
    
    color: #5C5C5C;
    
    
        }


.content-writing-row{
    position:relative;
    display:flex;
    color: #5C86A7;
    height:100%;
    vertical-align: middle;
    flex-direction: row;
    margin: auto;
    padding: 5%;
}
.content-writing-row h3{
    position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 64px;
margin-left: 10px;

}
.body-service-stack{
    position: relative;
    height:auto;
    background: #001B26;
    box-shadow: -10px 0px 250px 3px rgba(64, 96, 108, 0.25);
    
    border-radius: 0px;
    display:flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    border-radius: 0px;
    padding-bottom: 50px;
    }
.logo-heading{
    width:100%;
    align-content: center;
    order: 2;  
}
.source-button{
    width: 250px;
    height: 30px;
    background: #20303D;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    text-align: center;
    float: center;
    color:#FFFFFF  ;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    padding:16px 14px;
    
    cursor: pointer;

    }
    .source-button:hover{
        background: #2a3f50;

    }
    .content-p{
        color: #000;
        font-size: 18px;
        width: 90%;
        cursor: pointer;
        margin: 5%;
        border-radius: 25px;
        padding: 5px;

    }

    .content-p:hover{
        color: #3E6E94;
        font-size: 18px;
        background-color: #FFFFFF;
        
    }


    .learn-more{
        margin: auto;
        margin-top: 10px;

    }
.div-pane{
    width:90%;
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-items: left;
    }
    .content-slides{
        font-size: 22px;
        text-align:left
    } 

.our-service-text{
    /* Our Service */
position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 64px;
/* or 80px */
text-align: center;
color: #FFFFFF;
text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.service-specific-text{

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 125%;
    /* identical to box height, or 35px */
    text-align: center;
    
    color: #004C67;
    }

.question-slides {
        display:none;
        width:100%;
        height:inherit;
        /* Our Service Stack */
    
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
    /* identical to box height, or 60px */
    
        color: #FFFFFF;
    
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    
    }

    .question-slides p{
        /* Our Dream is to make every digital dream come true   Our Dream is to make every digital dream come true   */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 125%;
/* or 35px */

color: #FFFFFF;


    }
    
    .question-slides h1{
        /* Our Dream is to make every digital dream come true   Our Dream is to make every digital dream come true   */


/* Our Service Stack */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 125%;
/* identical to box height, or 60px */

color: #FFFFFF;

text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);



    }
    .question-button{
        /* Rectangle 402 */
    
    width: 30%;
    height: 50px;
    background: #243D4D;
    border-radius: 10px;
    border: none;
    color:#fff;
    cursor: pointer;
    }
.question-button:hover{

    /* Rectangle 401 */
    background: #35BBEA;
    color:#000;
}
.question-slide-pane{
    width: 100%;
    height: auto;
    display: inline-flex;
    margin: auto;
    flex-direction: row;
    background: #00121D;
    border-radius: 10px;
    align-items: center;

    }
    .question-slide-pane p{
        padding-left: 20px;
        padding-right: 20px;
        width:40%;
        color: #fff;
    }
.service-stack-row{
    width: 100%;
    height:auto;
    display:flex;
    flex-direction: row;
    cursor:pointer;
}
.service-stack{
    background: #003344;
    color: #fff;
    width: 100%;
    padding: 5%;
    text-decoration: none;
    margin-bottom: 5%;

} 



.service-stack:hover{
    background: #35BBEA;
    color: #000;
    

    }
.service-stack:hover svg path{
    stroke:#000;
}
.service-stack svg path{
    stroke:#fff;
}
.service-stack .circle{
    fill:#fff;
}
.service-stack:hover .circle{
    fill:#000;
}
.service-stack .svg_icon{
    fill:#35BBEA;
}
.service-stack:hover .svg_icon{
    fill:#000;
}

.service-stack{
    border-radius: 30px 30px 30px 30px;
}
#slides{
    min-height: 550px;
    height:auto;
    text-align: center;
}
.service-stack-row{
    width: 100%;
    height:auto;
    display:flex;
    flex-direction: column;
    cursor:pointer;
}
@media only screen and (min-width:700px) {

  
    .service-stack{
        background: #003344;
        color: #fff;
        width: 50%;
        padding: 5%;
        text-decoration: none;
        border-radius: 0px 0px 0px 0px;

        margin-bottom: 0%;

    } 
    .service-stack-row{
        width: 100%;
        height:auto;
        display:flex;
        flex-direction: row;
        cursor:pointer;
    }
    .service-stack-first-row-first-col{
        border-radius: 30px 0px 0px 0px;
        }
        /* Rectangle 12 */
        .service-stack-first-row-second-col{
            border-radius: 0px 30px 0px 0px;
        
        }
        .service-stack-third-row-first-col{
            border-radius: 0px 0px 0px 30px;
            }
        
        .service-stack-third-row-second-col{
            border-radius: 0px 00px 30px 0px;
        
        }
    .display-heading-text-h1{
        font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size:4em;
line-height: 125%;
/* or 92px */

color: #007BB6;
    }

    .body-display .div-50{
        margin-top:10vh;
    }
    .display-heading-tag-h1{
        /* Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. */



font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 1.75em;
line-height: 125%;
/* or 30px */

color: #5C5C5C;

justify-content: left;
    }


    .our-service-heading-text{
        /* Lorem Ipsum is simply dummy text of the printing */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 2em;
line-height: 125%;
/* or 45px */

color: #000000;


    }
    .our-service-para-text{

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 0.625em;
line-height: 125%;
/* or 25px */

color: #5C5C5C;


    }
    .body-display{
        position:relative;
        top:20vh;
        width:90%;
        padding-left: 10%;
        height:100vh;

        display: flex;
        flex-direction: row;
        justify-content: center;
        margin:0 auto;
    }
    .question-slide-pane{
        width: auto;
        height: auto;
        display: inline-flex;
        margin: auto;
        flex-direction: row;
        background: #00121D;
        border-radius: 10px;
        align-items: center;
    
        }
        .question-slide-pane p{
            padding-left: 20px;
            padding-right: 20px;
            color: #fff;
        }
    .body-service{
        position: relative;
        height:auto;
        padding-top: 80px;
        background: #F9F9F9;
        padding-bottom: 50px;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        padding-left: 10%;
        padding-right: 10%;
        flex-direction: row;
        width: 80%;
    }
    .body-service-stack{
        position: relative;
        height:auto;
        padding-left: 0%;
        padding-right: 0%;
        background: #001B26;
        box-shadow: -10px 0px 250px 3px rgba(64, 96, 108, 0.25);
        border-radius: 0px;
        display: flex;
        padding-left: 10%;
        padding-right: 10%;
        flex-direction: row;
        width: 80%;
    }

    .learn-more{
        margin-right: 5%;
    }
    .logo-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }

    .our-service-text{
        text-align: left;  
        left:10%;
        width:80%;
    }
    .div-pane{
        text-align: left;  

        width:50%;
        
        }

        #slides{
            height:auto;
            min-height:550px;
            text-align: left;
        }
        .question-button{
            /* Rectangle 402 */
        
        width: 134px;

        }
}
.heading-text-h3{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
}
a{
    text-decoration: none;
}
.para-text-h3{
    font-weight: 500;
    font-size: 16px;
}
.heading-text-h1{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 53px;
    padding: 10px;
    /* Help Every Digital Dream Come Alive */
}
.underline{
    -webkit-text-decoration-line: underline !important;
            text-decoration-line: underline !important;
    color: #5C86A7;

}
.display{
    display: block !important;

}
.heading-text-h2{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 53px;
    margin:auto;
    text-align: left;
}

.text-paragraph{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
}
.underline{
    text-decoration: underline;
}
.row{
    position: relative;
    text-align: left;  
    display: inline-flex;
    flex-direction: row;
   
}

.box{
    
    height: auto;
    padding: 25px;
    margin: 25px;
    display: inline-block;
    box-shadow: 0px 8px 20px 8px rgba(96, 96, 96, 0.25);
    border-radius: 20px;
    }
.box-md-1{
    width:100%;
}
.box-md-3-5{
    width:35%;
}
.box-md-2{
    width:50%
}
.box-md-2-5{
    width:65%
}
.box-md-3{
    width:33%
}
.box-md-4{
    width:25%
}
.box-md-5{
    width:20%;
}

.box-md-7{
    width:14.3%;


}
.text-center{
    text-align: center;  

}
.text-left{
    text-align: left;
}
.white-background{
    background: #fff;
}
.fade-in {
    -webkit-animation: fadeIn 3s forwards;
            animation: fadeIn 3s forwards;
  }
.div-30{
    width: 30%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-40{
    width: 40%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-50{
    width: 100%;
    height: auto;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-60{
    width: 70%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-70{
    width: 70%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-33{
    width: 33.33%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;
}

.div-num h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;


color: #000000;



}
.div-num h3{
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;

}
.div-num h1{
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;

}
.div-num p{
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;

}
.div-num ul{
    padding-left: 15%;
    padding-right: 10%;
    text-align: left;
}

.div-num h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
}
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }


  /*Collapsible*/
  .collapsible {
    background-color: #F9F9F9;
    color: #000;
    cursor: pointer;
    padding-bottom: 18px;
    padding-top: 18px;
    padding-right:5%;
    width: 90%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-top: 1px solid #E6E6E6;
    vertical-align: middle;
  }

  .active {
    border-top: 3px solid #455A64;
  }
  
  .collapsible:hover {
    background-color: rgb(235, 231, 231);
    border-top: 2px solid #455A64;

  }

  .content {
    max-height: 0;
    width: 90%;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .expand{
    content:url(/static/media/up_list.4899be79.svg);
    }
    .collapse{
        content:url(/static/media/down_list.2a553d60.svg);
    }



      .owl-theme .owl-nav [class*='owl-']{
        background: none !important;
      }
      .owl-next:focus, .owl-prev:focus {outline: none;}
    @media only screen and (min-width:700px) {
        .display{
        
            width: 100%;
        }
        .box-md-6{
            width:16.7%;
            padding: 2%;
            margin: 2%;
        }
    }
    .empower{
        text-align: left; 
        width: 50%;
    }
    .owl-theme{
        width:70%;
        overflow: hidden;
        outline: none;
      }
      .active{
        border-top:none
      }
      .body-empower{
        position: relative;
        height:auto;
        padding-top: 80px;
        background: #F9F9F9;
        padding-bottom: 50px;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
    
        flex-direction: row;
        width: 100%;
    }

@media only screen and (max-width:700px) {

    .div-50{
        width: 50%;
        height: 100%;
        text-align: left;
        vertical-align: left;  
        text-align: left;
    }
    .div-60{
        width: 90%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    
    
    }

    .heading-text-h2{
        
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        margin: auto;
        text-align: center;
        /* or 91px */
        
        
        }
        .row{
            text-align: center;  
            display: flex;
            flex-direction: column;
           
        }
        .tools-row{
            text-align: center;  
            display: inline-block;
            flex-direction: row;

        }
        .box{
            width: 80%;
            margin: 5%;
            
            padding: 5%;
        }
        .box-md-6{
            width:45%;
            padding: 2%;
            margin: 2%;
        }
        .empower{
            text-align: left; 
            width: 100%;
        }
        .owl-theme{
            width:100%;
            overflow: hidden;
          }
          .body-empower{
            position: relative;
            height:auto;
            padding-top: 80px;
            background: #F9F9F9;
            padding-bottom: 50px;
            padding-left: 0%;
            padding-right: 0%;
            display: flex;
        
            flex-direction: column;
            width: 100%;
        }
}



.fourthDisplay{
    position: relative;
    height:auto;
    
    padding-top: 70px;
    border-radius: 0px;
    padding-bottom: 50px;
    }
    .backgroundImage-prototype{
        position: absolute;
        right:0px;
        top:250px;
        height:600px;
        width:50%;
        min-width:400px;
        z-index: .20;
    }
    .prototype{
        z-index: .80;
        width:80%;
        padding-left: 10%;
        padding-right: 10%;
        text-align: left;
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        font-size: 48px;
        line-height: 90%;
    }
    .prototype prototype{
        text-decoration: underline;
        font-size:64px;
    }

    .prototype-stack{
        /* Rectangle 32 */

        box-sizing: border-box;

        width: 225px;
        height:auto;
        min-height: 250px;
    
        position:relative;
        background: #FFFFFF;
        border: 3px solid #D3D3D3;
        box-shadow: 3px 7px 30px 5px rgba(151, 160, 178, 0.25);
        border-radius: 20px;
        font-weight: 700;
        padding: 10px;
        text-align: left;
        font-size: 20px;
    }
.prototype-stack h3{
        position: relative;
        top:30px;
        line-height: 125%;
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 100%;
        color: #000000;
    }

    arrow{
            /* Explore */

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 20px */

color: #3A66FF;


    }
    .prototype-stack p{
        position: relative;
        top:40px;
        line-height: 100%;
        /* Our vision is to help every digital dream come alive through a better and sustain security */


font-weight: 400;
font-size: 22px;
line-height: 100%;
/* or 28px */

color: #000000;


    }
    .prototype-flex{
        height: 1300px;
        margin-left: 0%;
        
    }
    .prototype-col{
        width:15%;
        min-width:200px;
        display:flex;
        flex-direction: row;
    }
    .color-tag{
        /* Rectangle 35 */

        position:absolute;
        top:20px;
        right:0px;
        /* Rectangle 33 */

width: 52px;
height: 38px;



border-radius: 5px 0px 0px 10px;

    }
    /* Build Your ‘Prototype‘ */

    @media only screen and (max-width:700px) {
        .prototype{
            z-index: .80;
            height:auto;
            padding-left: 0%;
            padding-right: 0%;
            text-align: center;
        }
        .prototype-col{
            width:100%;
           padding: 0%!important;           
           min-width: 100%;
        }
        .prototype-stack{
            height:260px;
            width:100%;
            opacity: 0.99;
            background: #FFFFFF;
            position:relative!important;
            left:unset!important;
            right:unset!important;
            top:unset!important;
            margin-left: 10%;
            margin-left: 10%;
            margin-top: 40px!important;

        }
        .prototype-flex{
        width:100%;
        height:auto;
        display:flex;
        flex-direction: column;
        }
        .prototype-flex img{
            display:none;
        }
    }

.fifthDisplay{
  
        position: relative;
        height:auto;
        
        border-radius: 0px;
        margin-left:5%;
        margin-right:5%;
        

        padding-top: 50px;
        padding-bottom: 100px;
        margin-bottom: 100px;
        border-radius: 0px;
        padding-bottom: 50px;
        align-items: center;
        text-align: center;
        width: 90%;
}
.fifthDisplaySub{
position: relative;
height:400px;

}
.bgContentWriting{
    width: 50%;
    height:100%;
    position: absolute;
    left:0%;
    top:0%;
}
.bgContentWritingData{
    /* Rectangle 393 */

box-sizing: border-box;

position: absolute;
width: 100%;
height:50%;
top:50%;
border: 1px solid #5C86A7;
box-shadow: 2px 10px 30px 10px rgba(92, 134, 167, 0.25);
border-radius: 40px;

}
.bgContentWritingDataText{
    position:inherit;
    width: 50%;
    height:100%;
    right:50px;
    top:0px;
    text-align: left;
}
.bgContentWritingDataText h2{
    /* Content Writing */



font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 150%;
/* identical to box height, or 60px */

color: #5C86A7;


}
.bgContentWritingDataText p{
    /* Confederacie will provide content writing service. If possible please use a good Ilustration in this section. */

width:50%;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 100%;
/* or 28px */

color: #000000;


}

.opacityBox{
    /* Rectangle 395 */

position: absolute;
top:350px;
left:0%;
width:100%;
height:60%;
opacity: 0.3;
background: conic-gradient(from 180deg at 50% 50%, #A6C4CF 0deg, #6A99AB 150deg, #A6C4CF 360deg);

}

.dev-phase-heading{

    /* Content Writing */


/* Development Phase */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 125%;
/* identical to box height, or 70px */

color: #000000;


/* identical to box height, or 70px */


}
.dev-phase-row{
        position:relative;
        display:flex;
        color: #5C86A7;
        vertical-align: middle;
        flex-direction: row;
        margin: auto;
        padding: 5%;
}

.dev-phase-row h3{
    /* Software Architecture and Development */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
/* identical to box height */

color: #5C86A7;


}
/* Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl */
.dev-phase-row p{


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;

color: #5C86A7;

}

.leftRightCircle{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: right;

  flex-direction: row;
}
.leftRightCircle div{
    /* Ellipse 16 */

    border-radius: 50%; 
    margin: 5px;
    width:50px;
    height:50px;
    display: flex;
  flex-direction: column;
  justify-content: center;
    text-align: center;
    color: #fff;
    background: linear-gradient(0deg, #5C86A7, #5C86A7), linear-gradient(0deg, #5C86A7, #5C86A7), linear-gradient(0deg, #5C86A7, #5C86A7), #5C86A7;

}


.content-writing-stack{
    position: relative;
    text-align: left;  
    display: inline;
    align-items: left;
    justify-content: left;
   }

   .content-writing-box{
    width: 220px;
    height: 320px;
    padding: 20px;
    margin: 20px;
    display: inline-block;
    border-radius: 20px;
    text-align: center; 
    opacity: 1.0; 

    /* Rectangle 396 */

box-sizing: border-box;

background: #f9f6f6;
border: 1px solid #5C86A7;
border-radius: 30px;
/* Software Architecture and Development */
font-family: 'Red Hat Display';
font-style: normal;
color: #5C86A7;

    }
    .content-writing-box:hover{
        /* Rectangle 397 */

background: #6A99AB;
border: 1px solid #5C86A7;
box-shadow: 2px 8px 30px 10px rgba(92, 134, 167, 0.25);
border-radius: 30px;
color: #fff;

    }
    .content-writing-box h1{
font-weight: 700;
font-size: 18px;

    }
    .content-writing-box svg{
        padding: 30px;
        fill:#6A99AB;
    }
    .content-writing-box:hover{
        width: 260px;
        height: 360px;
    }
    .content-writing-box:hover svg{
        width:72px;
        height:40px;
        fill:#fff;
    }
    .content-writing-box p{

    /* Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl */

font-weight: 500;
font-size: 14px;
}
.contentWritingBottomImg{
    width:100%;
    justify-content: right;
    text-align: right;
}
@media only screen and (max-width:700px) {
    .contentWritingBottomImg{
        width:100%;
        justify-content: center;
        text-align: center;
    }
    .contentWritingBottomImg img{
        width:100%;
    }
}



.sixthDisplay{
  
    position: relative;
    height:auto;
    
    border-radius: 0px;

    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-radius: 0px;
    padding-bottom: 50px;
    align-items: left;
    text-align: left;
}
.augmented-team-stack{
    position: relative;
    text-align: left;  
    display: inline;
    align-items: left;
    justify-content: left;
   }

   .augmented-team-box{
    position:relative;
    top:0px;
    left:0px;
    width: 26%;
    min-height: 400px;
    height:auto;
    padding: 20px;
    margin: 20px;
    display: inline-block;
    border-radius: 20px;
    text-align: left; 

/* Software Architecture and Development */
font-family: 'Red Hat Display';
font-style: normal;
color: #000000;
/* Rectangle 45 */

box-sizing: border-box;

background: #FFFFFF;
border: 4px solid #D9D9D9;
box-shadow: 0px 4px 40px 15px rgba(217, 217, 217, 0.4);
border-radius: 30px;

    }

.augmented-team-box p{
font-weight: 500;
font-size: 14px;
}
.augmented-team-box h1{
    /* Name */

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 20px;
/* identical to box height */




}

/* Position */

.augmented-team-box h3{

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #A3A3A3;

}

.augmentedTeamHeading{
    text-align: left;
    width: 100%;
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 90%;


}

.body-visulization{
    position: relative;
    height:auto;
    padding-left: 5%;
    padding-right: 5%;;
    height: 100vh;
    top:105px;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
}
.body-decision{
    position:relative;
    top:10vh;
    display:flex;
    width: 100%;
    height:90vh;
    margin-top: 150px;
    flex-direction: column;
    padding-bottom: 200px;

}
.body-dashboard{
    position: relative;
    height:auto;
    display:flex;

padding: 10px;

flex-direction: column;

}
.decision-background{


/* Rectangle 477 */

position: absolute;
margin-top: 100px;
left: 0px;
width: 80%;
height:50vh;
background: rgba(92, 134, 167, 0.1);
border-radius: 0px 200px 200px 0px;
z-index:-1;
top:-200px;
}
.logo-heading{
    width:100%;
    align-content: center;
    order: 2;  
}
.decision-board-heading{
    width:100%;
    align-content: center;
    order: 2;  
    z-index:100;
}

.decision-board-text{
    width: 100%;
    z-index:100;

}

.visualiztion-heading{
    width:80%;
    z-index: 1;  
    position: absolute;
    top: 0px;
    left: 0px;
    padding-left: 10%;
    padding-right: 10%;
    
}


.dashboard-nav{
    /* Rectangle 478 */
    width: 100%;
    height:auto;
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;


    border-radius: 0px 0px 50px 50px;

background: rgba(92, 134, 167, 0.1);

}

.dashboard-nav p{
    /* Connect */

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 20px;
text-decoration: none;
color: #000000;


}



.dashboard-nav p:hover{
    /* Explore */
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;

color: #5C86A7;
cursor: pointer;

}


.truthboard-nav{
    /* Rectangle 478 */
    width: 90%;
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: auto auto auto auto auto;
    text-align: center;
    border-radius: 5px 5px 5px 5px;
    background: rgba(92, 134, 167, 0.1);

}
.truthboard-nav p{
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5.5%;

}
.truthboard-nav p:hover{

    background: #000;
    color: #fff;
    margin-top: 0px;

}

.highlight{
    background: #000;
    color: #fff;
    margin-top: 0px;
}

.truthboard-box{
    /* Rectangle 482 */

box-sizing: border-box;
padding-left: 2%;
padding-right: 2%;
padding-top: 15px;
margin: 5%;

background: #FFFFFF;
border: 0.5px solid #6A99AB;
box-shadow: 2px 10px 20px rgba(106, 153, 171, 0.25);
border-radius: 10px;
height:200px;
width: unset;
}


.truthboard-stack{
    display:flex;
    flex-direction: column;

}

@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {
    .body-visulization{
        position:relative;
        width:80%;
        height:auto;
        display: flex;
        flex-direction: row;
        text-align:'left';
        top: 150px;
        padding-bottom: 150px;

    }
    .body-decision{
        position:relative;
        top:30vh;
        width:90%;
        height:90vh;;
        left: 0px;
        display: flex;
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: row;
        justify-content: center;
        z-index: 0.99;
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;
        z-index:100;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
        z-index:100;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        min-height:600px;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}



.navigation {
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top:0px;
  padding: 5px 0px;
  background-color: #302d2d;
  color: #FFF;
  justify-content: center;
  align-items: center;
  
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}
.brand-name {
  text-decoration: none;
  color: #FFF;
  font-size: 1.3rem;
  justify-content: center;
    align-items: center;
}
.navigation-menu {
  justify-content: center;
  align-items: center;  
}
.navigation-menu ul {
  display: flex;
  padding: 0;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #bfbaba;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #FFF;
}
@media screen and (max-width: 768px) {
  .navigation{
    position:fixed;
    z-index: 99;
  }
  .navigation-menu ul {
    position: absolute;
    top: 60px;  
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
    display: none;

  }
  .navigation-menu li {
    text-align: center;
    margin: 0;

  }
  .navigation-menu li a {
    color: black;
    width: 90%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .hamburger {
    display: block;
  }
}
.bottomNav{
    /* Rectangle 44 */
background: rgba(166, 196, 207, 0.25);
border-radius: 0px;
width: 80%;
min-height: 300px;
height:auto;
position: absolute;
padding: 10%;

}
.confederacie-bottom-text{
    font-size: 48px;
}
.box-nav{
    margin:20px;    
    background-color:none;
    box-shadow:none;
}
.box-nav-link{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color:#5C86A7;
}
.body-governance{
    top: 65px;
    position: relative;
    height:auto;
    padding-bottom: 65px;
    justify-content: left;
    align-items: center;
    vertical-align: middle;  
    text-align: center;
}
.works-governance{
    position: relative;
    height: auto;
    justify-content: left;
    align-items: center;
    vertical-align: middle;  
    text-align: center;
}
.tilt-90-button{

width: 100%;
height: 20%;
cursor: pointer;

}
.color-box-shade{
    /* Vector */
    width: 100%;
    height: 100%;
/* Vector */  

}
.governance-slides{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.service-button{
    width: 200px;
    max-width: 30%;
    height: 30px;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
    text-align: center;
    float: center;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    padding:16px 14px;
    border: #000000;
    margin-top: 40px;
    border-style: solid;
    cursor: pointer;
    background:#000;

    }
    .service-button:hover{
        background: #242424 !important;
        color: #fff !important;

    }
    .tilt-90-button svg{
        cursor: pointer;

        fill: none;
        stroke: #000;
    }
    .tilt-90-button:hover svg{
        cursor: pointer;

        fill: #000;
        stroke: #000;
    }
    .tilt-90-button:hover svg path{
        fill: #fff;
        stroke: #fff;
    }
    .tilt-90-button svg path{
        fill: #000;
        stroke: #000;
    }

    .button-active{

    }
    .button-active svg{

        fill: #000;
        stroke: #000;

    }
    .button-active svg path{
        fill: #fff;
        stroke: #fff;
    }



.color-box-shade div{
    /* Vector */
    
position: relative;
}
.color-box-shade div img{
    width: 50px;
    height:10px;
    
}
.box-md-1 .color-box-shade img{
    /* Vector */
    position: relative;
    left:35%;
    top:-5%;
width: 70%;
height: 40%;


}
.box-md-3 .color-box-shade img {
    position: none;
    left: none;
    top: -5%;
    width: 30%;
    height: 15%;
}


section{
    position: relative;  
    background-color: #69666c;
    height: 450px;
    width: 100%;
    display: flex;
    align-items: center;
    
  }
.swiper{
    width: 90%;
  }
  
  .card{
    position: absolute;
    background: rgb(197, 67, 67);
    border-radius: 20px;
    height: 100%;
    width: 400px;
    margin: 20px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  
  .card::before{
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    background: white;
    border-radius: 20px 20px 0 0;
  }
  
  .card .card-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 100;
  }
  
  section .card .image{
    height: 140px;
    width: 140px;
    border-radius: 20%;
    padding: 3px;
    background: #e3e1e4;
    margin-top: 30px;
  }
  
  section .card .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20%;
    border: 3px solid #fff;
  }
  
  .card .media-icons{
    position: absolute;
    top: 12px;
    right: 95px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .card .media-icons i{
    color: #7d2ae8;
    opacity: 0.6;
    margin-top: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 10px;
  }
  
  .card .media-icons i:hover{
    opacity: 1;
  }
  
  .card .name-profession{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    color: black;
  } 
  
  .name-profession .name{
    font-size: 20px;
    font-weight: 600;
  }
  
  .name-profession .profession{
    font-size:15px;
    font-weight: 500;
  }
  
  .card .rating{
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
  
  .card .rating i{
    font-size: 18px;
    margin: 0 2px;
    color: #7d2ae8;
  }
  
  .card .button{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .card .button button{
    background: #7d2ae8;
    outline: none;
    border: none;
    color: #fff;
    padding: 8px 22px;
    border-radius: 10px;
    font-size: 14px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .button button:hover{
    background: #6616d0;
  }
  
  .swiper-pagination{
    position: absolute;
    top: 450px;
  }
  
  .swiper-pagination-bullet{
    height: 7px;
    width: 26px;
    border-radius: 25px;
    background: white;
  }
  
  .swiper-button-next, .swiper-button-prev{
    opacity: 0.7;
    color: white;
    transition: all 0.3s ease;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover{
    opacity: 1;
    color: white;
  }
  .security-display{
    width: 400px;
    max-width: 100%;
}
@media only screen and (max-width:700px) {
.truth-box{
    width: 100%!important;
}



}
@media only screen and (min-width:700px) {

    .body-governance{
        position: relative;
        top:70px;
        width:100%;
        height: 100vh;
        padding-bottom: 0px;

        display: flex;
        flex-direction: row;
    }
    .box-md-3 .color-box-shade img{
        /* Vector */
        position: relative;
        left:75%;
        top:-5%;
    width: 30%;
    height: 15%;
    
    
    }

    .works-governance{
        position: relative;
        top:70px;
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .governance-slides{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .tilt-90-button{
        position: relative;
        width: 15%;
        height: 100%;
        /* Security */

        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        text-align: center;
        }

    .works-background{
        position: relative;
        width: 100%;
        background-image: url(/static/media/works-background.a50938d8.png);
        height:300px;
        margin-top: 100px;
    }


    .tilt-90-button div{
/* Security */



        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 141.3%;
        /* or 127px */
        letter-spacing: 0.04em;

        color: #000000;
        padding: 20px;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
    .tilt-90-button svg{
        /* Security */
        display: block;
        align:center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        padding-bottom: 100px;

                
            }
    .body-governance .display{
        width: 100%;
        height: 100%;
        /* Rectangle 407 */


background: rgba(106, 153, 171, 0.25);


    }

/* Vector */



/* Vector */

.security-box{
    box-sizing: border-box;

    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    box-shadow: 2px 8px 30px 5px rgba(176, 176, 176, 0.25);
    border-radius: 20px;
    
}

  
/* Rectangle 429 */


}
.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}


.vir-active{
    color: #6A99AB;

}

.checkMarkDiv{
    display:flex;
    text-align: center;
    padding-top: 10px;
    margin-left:10% ;

}
.div-50-para{
    margin-left: 10%;
}
.checkMarkDiv img{
    width:15px;
    padding-right: 15px;
    

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}
.vir-list{
    position:relative;
    height:auto;
    width: 100%;
    bottom:0px;
    /* Linux and Windows */
    display: flex;
    flex-direction: row;
    margin-top: 5%;

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    /* identical to box height */



}
.vir-list h3{
    padding: 10px;
    padding-top: 10px;
    color: rgba(106, 153, 171, 0.5);


}

.display-overlay{
position: relative;
top:15vh;
left:10%;
width:0px;
height: 0px;
}


    .truthboard-box{
        width: 90% !important;
    }

.display-virtualization-text{
    position: relative;
    /* Virtualization */
    text-align: center;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */

    color: #4A7E93;


}
.background-bg{


    width: 100%;
    height: auto;

}

.display-virtualization-para{
    position: relative;
    width:90%;
    padding: 5% ;
    /* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 29px;


color: #000000;


    /* Virtualization */
text-align: center;
/* identical to box height */

}

    .body-virtualization{
        position: relative;
        top:75px;
        width:90%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        
    }

        .rectangle-display1{
            width: 100%;
            height: auto;
            
        }
        .rectangle-display1 h3{
            font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 18px;
margin: auto;
text-align: center;
padding-top: 50px;
/* identical to box height */
cursor: pointer;
color: #6A99AB;
        }
        .rectangle-display2{
            /* Rectangle 443 */
            width:90%;
            height: auto;
            padding: 5%;
            justify-content: center;
            align-items: center;
            vertical-align: middle;  
            text-align: center;
            background: rgba(106, 153, 171, 0.25);
        
        }

        .display-overlay-bg{

    
            width:0px;
            height: auto;
    
            }


@media only screen and (min-width:700px) {
  
    .body-virtualization{
        position: relative;
        top:75px;
        width:100%;
        height:100vh;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
    }
    .background-bg{

        opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 0px;
        height: 0px;
        
    }
    .display-overlay{

        position: relative;

        width:40vw;
        height: auto;

        }
        .vir-list{
            position:absolute;
            width: 30%;;
            height:10%;
            bottom:15vh;
            display: flex;
            flex-direction: column;
            font-family: 'Red Hat Display';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            text-align: left;
    text-align: left;

/* identical to box height */

        }
        .vir-list h3{
            padding: 10px;
            /* Serverless */
        color: rgba(106, 153, 171, 0.5);

        text-align: left;

        
        }

        .display-overlay-bg{

    
            width:100%;
            height: auto;
    
            }
    .rectangle-display1{
        width: 30%;
        height: 100%;
        margin-top: 20px;
    }
    .rectangle-display2{
        /* Rectangle 443 */
        width:65%;
        height: 100%;
        padding: 0%;
        padding-right: 5%;

        justify-content: center;
        align-items: center;
        
        vertical-align: middle;  
        text-align: center;
        background: rgba(106, 153, 171, 0.25);
    
    }

    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;

    }
    
    
    .display-virtualization-para{
        position: relative;
        top:20vh;
        width:60%;
        left:40%;
        padding: 0%;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    
    color: #000000;
    
    
        /* Virtualization */
    text-align: left;
    /* identical to box height */
    
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}
.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}


.body-prototyping{
    position: relative;
    top:73px;
    width:100%;
    height:auto;
    display: flex;
    background-image: url(/static/media/display-background.daa46832.svg);
    flex-direction: column;
    vertical-align: middle;  
    text-align: center;
}

.mob-display{
    display: block;
}

.box-prototyping{
    
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    display: inline-block;
    color:#FFFFFF;
    text-align: center;
    }

    .body-works{
        position: relative;
        width:90%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .service-specific-text-prototyping{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        /* identical to box height, or 35px */
        text-align: center;
        
        color: #fff;
    }
    .row-box{
        text-align: center;  
        
    }
.body-prototype-service{
    /* Rectangle 456 */
    position: relative;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;

    height:auto;
    padding-top: 50px;
background: #000000;
}
.div-50{
    width: 100%;
    height: auto;
    text-align: left;

    vertical-align: left;  
    text-align: left;
}
.body-prototyping-bg{
    /* Rectangle 457 */
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    vertical-align: middle;  
    text-align: center;
    padding-bottom: 10%;
background: rgba(106, 153, 171, 0.1);

}
.display-block-overlay{
    width:0px;
    height: 0px;
    display: none;
    }

.checkMarkDiv{
    display:flex;
    text-align: center;
    padding-top: 10px;
}
.checkMarkDiv img{
    width:5px;
    padding-right: 15px;

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}

.div-50 h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;
display: flex;
align-items: flex-end;

color: #000000;


}
.rectangle-display{
    width: 90%;
    height: auto;
    padding: 5%;

}



.div-50 p{


}
.div-50 h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 25%;
}






.vir-button{
    /* Rectangle 443 */
    position: relative;
max-width: 300px;
cursor: pointer;
left:10%;
padding-top:10px;
margin-top: 50px;
padding-bottom:10px;
margin: 10px;
text-align: center;
/* Share Your Requirement */
background-color: #000;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.04em;
display: block;
color: #fff;


}

.vir-button:hover{

background-color: rgb(19, 19, 19);
}


@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {
    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;
    
    
    }
    .box-prototyping{
    
        height: auto;
        padding: 55px;
        margin: 20px;
        width: 33.33%;
        margin-right: 60px;
        margin-left: 60px;
        display: inline-block;
        color:#FFFFFF;
        text-align: center;
        }
    .mob-display{
        display: none;
    }
    
    .rectangle-display{
        width: 50%;
        height: 100%;
        padding: 50px;
    
    }
    .div-50{
        width: 100%;
        padding: 0%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    }
    
    .display-block-overlay{


        width:initial;
        height: initial;
        display: block;

        }
    .body-prototyping-bg{
        /* Rectangle 457 */
        position: relative;
        width:100%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
    background: rgba(106, 153, 171, 0.1);
    
    }

    .body-prototyping{
        position: relative;
        top:73px;
        width:100%;
        height:100vh;
        display: flex;
        background-image: url(/static/media/display-background.daa46832.svg);
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
    }
    .body-works{
        position: relative;
        width:90%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}
.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}

.div-mob{
    width: 100%;
}

.box-prototyping{
    
    height: auto;
    padding: 25px;
    margin: 20px;
    display: inline-block;
    color:#FFFFFF;
    text-align: center;
    }
.logo-row{
    display: flex;
    flex-direction: row;
}
    .body-sd{
        position: relative;
        width:100%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
        top:105px;
    }
    .service-specific-text-prototyping{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        /* identical to box height, or 35px */
        text-align: center;
        
        color: #fff;
    }
    .row-box{
        text-align: center;  
        
    }
.body-prototype-service{
    /* Rectangle 456 */
    position: relative;
    height:auto;

background: #000000;
}
.div-50{
    width: 90%;
    padding: 5%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: center;
}

.checkMarkDiv{
    display:flex;
    text-align: left;
    padding-top: 10px;
}
.checkMarkDiv img{
    width:15px;
    padding-right: 15px;

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}

.div-50 h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;
display: flex;
align-items: flex-end;

color: #000000;


}




.div-50 p{

    padding-left: 0%;
    padding-right: 0%;
}
.div-50 h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 25%;
}


.bg-black::before {
    background-color: black;
    color:#fff;
  }
  ol {
    counter-reset: myOrderedListItemsCounter;
    
  }
  ol li {
    list-style-type: none;
    position: relative;
    padding: .4em;

  }
  ol li:before {
    counter-increment: myOrderedListItemsCounter;
    content: counter(myOrderedListItemsCounter)".";
    margin: .5em;
    border-radius: 50%;
    padding-right: .2em;
    padding-left: .2em;

  }
.bodyArchDesign{
    /* Rectangle 495 */

width: 100%;
height: auto;
background: linear-gradient(180deg, rgba(92, 134, 167, 0) 0%, rgba(92, 134, 167, 0.2) 100%);

}
@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {

    .body-sd{
        position: relative;
        width:100%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
        top:65px;
    }

    .div-50{
        width: 100%;
        padding: 0%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    }
    .div-50 p{

        padding-left: 0%;
        padding-right: 10%;
    }

    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;
    
    
    }

    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}

.body-augmented{
    background-image: url(/static/media/augmented_bg.a1547c1d.png);
    width:100%;
    height:100vh;
    color:#fff;
    text-align: center;
    display: block;
    
    
}
.body-what-is-great{
    width:100%;
    color:#000;
    text-align: center;
    
    
}
.body-augmented h1{
    line-height: 1.5;
    text-align:center;
    justify-content: center;
    display: inline-block;
    vertical-align: middle;
}
.body-augmented h3{
        line-height: 1.5;
    text-align:center;
    justify-content: center;
    display: inline-block;
    vertical-align: middle;
}
.WhatIsGreat-box{
    /* Rectangle 512 */

width: 100%;
background: #FFFFFF;
box-shadow: 4px 8px 30px 5px rgba(92, 134, 167, 0.25);
border-radius: 10px;
color:#000;
padding: 5px;
margin: 5px;
}
.WhatIsGreat-box-inline{

 width: 100%;
}
.WhatIsGreat-box-inline h3{
    text-align: left;
    margin:10px;
    vertical-align: super;

}
.WhatIsGreat-box-inline div{
    text-align: left;
    margin:2.5%;
    vertical-align: super;
    width:95%;

}
.great-slides{
    display:none;
    text-align: center;
    /* Our Service Stack */
    font-family: 'Red Hat Display';

    font-style: normal;
    font-weight: 700;
/* identical to box height, or 60px */


    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

}
#great-slides{
width: 100%;

}
.body-amazing-team{
    width: 90%;
    display: flex;
    flex-direction: column;

}
.great-slides-div-pane{

    width:100%;
    
    }
.body-talent{
    /* Rectangle 519 */
width: 100%;
text-align: center;
background: rgba(217, 217, 217, 0.2);
/* Leverage World-Class Talent */


/* identical to box height */




}
.body-understand-employees{
    /* Rectangle 519 */
width: 100%;
text-align: center;
margin: 0 auto;
display: flex;
background: rgba(217, 217, 217, 0.2);
flex-direction: column;

}
.inter-heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
}
.body-talent h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
/* Leverage World-Class Talent */



}
.body-contact{
    background:linear-gradient(180deg, #18476C 50%, #fff 50%);
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.input-box{
    /* Rectangle 529 */

box-sizing: border-box;

background: #FFFFFF;
border: 1px solid #CFCFCF;
border-radius: 5px;
/* Rectangle 529 */

box-sizing: border-box;
padding-left: 10px;
padding-right: 10px;

height: 50px;


background: #FFFFFF;
border: 1px solid #CFCFCF;
border-radius: 5px;


}
@media only screen and (min-width:700px) {
    .body-understand-employees{
        /* Rectangle 519 */
    width: 100%;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    background: rgba(217, 217, 217, 0.2);
    
    }
    .WhatIsGreat-box-inline{
        display: inline-flex;
    flex-direction: row;
     width: 100%;
    }

    .body-amazing-team{
        width: 100%;
        display: inline-flex;
        flex-direction: row;
    
    }
    .body-contact{
        background:linear-gradient(180deg, #18476C 50%, #fff 50%);
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        color: #fff;
        display: flex;
        flex-direction: row;
    }

}

