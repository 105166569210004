.body-display{
    position:relative;
    display:flex;
    flex-direction: column-reverse;
    position:relative;
    top:10vh;
    width:100%;
    
    height: 140vh;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    align-items: center;
    
}

.body-display .div-50{
    margin-top:0vh;
}
.body-service{
    position: relative;
    height:auto;
    padding-top: 80px;
    background: #F9F9F9;
    padding-bottom: 50px;
}
/* Our Service Stack */



.display-heading-text-h1{
    font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 4.625em;
line-height: 125%;
/* or 92px */

color: #007BB6;
}

.our-service-heading-text{
    /* Lorem Ipsum is simply dummy text of the printing */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 2em;
line-height: 125%;
/* or 45px */

color: #000000;



}
.our-service-para-text{

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 0.625em;
    line-height: 125%;
    /* or 25px */
    
    color: #5C5C5C;
    
    
        }


.content-writing-row{
    position:relative;
    display:flex;
    color: #5C86A7;
    height:100%;
    vertical-align: middle;
    flex-direction: row;
    margin: auto;
    padding: 5%;
}
.content-writing-row h3{
    position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 64px;
margin-left: 10px;

}
.body-service-stack{
    position: relative;
    height:auto;
    background: #001B26;
    box-shadow: -10px 0px 250px 3px rgba(64, 96, 108, 0.25);
    
    border-radius: 0px;
    display:flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    border-radius: 0px;
    padding-bottom: 50px;
    }
.logo-heading{
    width:100%;
    align-content: center;
    order: 2;  
}
.source-button{
    width: 250px;
    height: 30px;
    background: #20303D;
    backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    text-align: center;
    float: center;
    color:#FFFFFF  ;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    padding:16px 14px;
    
    cursor: pointer;

    }
    .source-button:hover{
        background: #2a3f50;

    }
    .content-p{
        color: #000;
        font-size: 18px;
        width: 90%;
        cursor: pointer;
        margin: 5%;
        border-radius: 25px;
        padding: 5px;

    }

    .content-p:hover{
        color: #3E6E94;
        font-size: 18px;
        background-color: #FFFFFF;
        
    }


    .learn-more{
        margin: auto;
        margin-top: 10px;

    }
.div-pane{
    width:90%;
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-items: left;
    }
    .content-slides{
        font-size: 22px;
        text-align:left
    } 

.our-service-text{
    /* Our Service */
position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 64px;
/* or 80px */
text-align: center;
color: #FFFFFF;
text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.service-specific-text{

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 125%;
    /* identical to box height, or 35px */
    text-align: center;
    
    color: #004C67;
    }

.question-slides {
        display:none;
        width:100%;
        height:inherit;
        /* Our Service Stack */
    
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
    /* identical to box height, or 60px */
    
        color: #FFFFFF;
    
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    
    }

    .question-slides p{
        /* Our Dream is to make every digital dream come true   Our Dream is to make every digital dream come true   */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 125%;
/* or 35px */

color: #FFFFFF;


    }
    
    .question-slides h1{
        /* Our Dream is to make every digital dream come true   Our Dream is to make every digital dream come true   */


/* Our Service Stack */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 125%;
/* identical to box height, or 60px */

color: #FFFFFF;

text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);



    }
    .question-button{
        /* Rectangle 402 */
    
    width: 30%;
    height: 50px;
    background: #243D4D;
    border-radius: 10px;
    border: none;
    color:#fff;
    cursor: pointer;
    }
.question-button:hover{

    /* Rectangle 401 */
    background: #35BBEA;
    color:#000;
}
.question-slide-pane{
    width: 100%;
    height: auto;
    display: inline-flex;
    margin: auto;
    flex-direction: row;
    background: #00121D;
    border-radius: 10px;
    align-items: center;

    }
    .question-slide-pane p{
        padding-left: 20px;
        padding-right: 20px;
        width:40%;
        color: #fff;
    }
.service-stack-row{
    width: 100%;
    height:auto;
    display:flex;
    flex-direction: row;
    cursor:pointer;
}
.service-stack{
    background: #003344;
    color: #fff;
    width: 100%;
    padding: 5%;
    text-decoration: none;
    margin-bottom: 5%;

} 



.service-stack:hover{
    background: #35BBEA;
    color: #000;
    

    }
.service-stack:hover svg path{
    stroke:#000;
}
.service-stack svg path{
    stroke:#fff;
}
.service-stack .circle{
    fill:#fff;
}
.service-stack:hover .circle{
    fill:#000;
}
.service-stack .svg_icon{
    fill:#35BBEA;
}
.service-stack:hover .svg_icon{
    fill:#000;
}

.service-stack{
    border-radius: 30px 30px 30px 30px;
}
#slides{
    min-height: 550px;
    height:auto;
    text-align: center;
}
.service-stack-row{
    width: 100%;
    height:auto;
    display:flex;
    flex-direction: column;
    cursor:pointer;
}
@media only screen and (min-width:700px) {

  
    .service-stack{
        background: #003344;
        color: #fff;
        width: 50%;
        padding: 5%;
        text-decoration: none;
        border-radius: 0px 0px 0px 0px;

        margin-bottom: 0%;

    } 
    .service-stack-row{
        width: 100%;
        height:auto;
        display:flex;
        flex-direction: row;
        cursor:pointer;
    }
    .service-stack-first-row-first-col{
        border-radius: 30px 0px 0px 0px;
        }
        /* Rectangle 12 */
        .service-stack-first-row-second-col{
            border-radius: 0px 30px 0px 0px;
        
        }
        .service-stack-third-row-first-col{
            border-radius: 0px 0px 0px 30px;
            }
        
        .service-stack-third-row-second-col{
            border-radius: 0px 00px 30px 0px;
        
        }
    .display-heading-text-h1{
        font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size:4em;
line-height: 125%;
/* or 92px */

color: #007BB6;
    }

    .body-display .div-50{
        margin-top:10vh;
    }
    .display-heading-tag-h1{
        /* Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. */



font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 1.75em;
line-height: 125%;
/* or 30px */

color: #5C5C5C;

justify-content: left;
    }


    .our-service-heading-text{
        /* Lorem Ipsum is simply dummy text of the printing */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 2em;
line-height: 125%;
/* or 45px */

color: #000000;


    }
    .our-service-para-text{

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 0.625em;
line-height: 125%;
/* or 25px */

color: #5C5C5C;


    }
    .body-display{
        position:relative;
        top:20vh;
        width:90%;
        padding-left: 10%;
        height:100vh;

        display: flex;
        flex-direction: row;
        justify-content: center;
        margin:0 auto;
    }
    .question-slide-pane{
        width: auto;
        height: auto;
        display: inline-flex;
        margin: auto;
        flex-direction: row;
        background: #00121D;
        border-radius: 10px;
        align-items: center;
    
        }
        .question-slide-pane p{
            padding-left: 20px;
            padding-right: 20px;
            color: #fff;
        }
    .body-service{
        position: relative;
        height:auto;
        padding-top: 80px;
        background: #F9F9F9;
        padding-bottom: 50px;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        padding-left: 10%;
        padding-right: 10%;
        flex-direction: row;
        width: 80%;
    }
    .body-service-stack{
        position: relative;
        height:auto;
        padding-left: 0%;
        padding-right: 0%;
        background: #001B26;
        box-shadow: -10px 0px 250px 3px rgba(64, 96, 108, 0.25);
        border-radius: 0px;
        display: flex;
        padding-left: 10%;
        padding-right: 10%;
        flex-direction: row;
        width: 80%;
    }

    .learn-more{
        margin-right: 5%;
    }
    .logo-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }

    .our-service-text{
        text-align: left;  
        left:10%;
        width:80%;
    }
    .div-pane{
        text-align: left;  

        width:50%;
        
        }

        #slides{
            height:auto;
            min-height:550px;
            text-align: left;
        }
        .question-button{
            /* Rectangle 402 */
        
        width: 134px;

        }
}