

.sixthDisplay{
  
    position: relative;
    height:auto;
    
    border-radius: 0px;

    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-radius: 0px;
    padding-bottom: 50px;
    align-items: left;
    text-align: left;
}
.augmented-team-stack{
    position: relative;
    text-align: left;  
    display: inline;
    align-items: left;
    justify-content: left;
   }

   .augmented-team-box{
    position:relative;
    top:0px;
    left:0px;
    width: 26%;
    min-height: 400px;
    height:auto;
    padding: 20px;
    margin: 20px;
    display: inline-block;
    border-radius: 20px;
    text-align: left; 

/* Software Architecture and Development */
font-family: 'Red Hat Display';
font-style: normal;
color: #000000;
/* Rectangle 45 */

box-sizing: border-box;

background: #FFFFFF;
border: 4px solid #D9D9D9;
box-shadow: 0px 4px 40px 15px rgba(217, 217, 217, 0.4);
border-radius: 30px;

    }

.augmented-team-box p{
font-weight: 500;
font-size: 14px;
}
.augmented-team-box h1{
    /* Name */

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 20px;
/* identical to box height */




}

/* Position */

.augmented-team-box h3{

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #A3A3A3;

}

.augmentedTeamHeading{
    text-align: left;
    width: 100%;
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 90%;


}
