.body-visulization{
    position: relative;
    height:auto;
    padding-left: 5%;
    padding-right: 5%;;
    height: 100vh;
    top:105px;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
}
.body-decision{
    position:relative;
    top:10vh;
    display:flex;
    width: 100%;
    height:90vh;
    margin-top: 150px;
    flex-direction: column;
    padding-bottom: 200px;

}
.body-dashboard{
    position: relative;
    height:auto;
    display:flex;

padding: 10px;

flex-direction: column;

}
.decision-background{


/* Rectangle 477 */

position: absolute;
margin-top: 100px;
left: 0px;
width: 80%;
height:50vh;
background: rgba(92, 134, 167, 0.1);
border-radius: 0px 200px 200px 0px;
z-index:-1;
top:-200px;
}
.logo-heading{
    width:100%;
    align-content: center;
    order: 2;  
}
.decision-board-heading{
    width:100%;
    align-content: center;
    order: 2;  
    z-index:100;
}

.decision-board-text{
    width: 100%;
    z-index:100;

}

.visualiztion-heading{
    width:80%;
    z-index: 1;  
    position: absolute;
    top: 0px;
    left: 0px;
    padding-left: 10%;
    padding-right: 10%;
    
}


.dashboard-nav{
    /* Rectangle 478 */
    width: 100%;
    height:auto;
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;


    border-radius: 0px 0px 50px 50px;

background: rgba(92, 134, 167, 0.1);

}

.dashboard-nav p{
    /* Connect */

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 20px;
text-decoration: none;
color: #000000;


}



.dashboard-nav p:hover{
    /* Explore */
text-decoration-line: underline;

color: #5C86A7;
cursor: pointer;

}


.truthboard-nav{
    /* Rectangle 478 */
    width: 90%;
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: auto auto auto auto auto;
    text-align: center;
    border-radius: 5px 5px 5px 5px;
    background: rgba(92, 134, 167, 0.1);

}
.truthboard-nav p{
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5.5%;

}
.truthboard-nav p:hover{

    background: #000;
    color: #fff;
    margin-top: 0px;

}

.highlight{
    background: #000;
    color: #fff;
    margin-top: 0px;
}

.truthboard-box{
    /* Rectangle 482 */

box-sizing: border-box;
padding-left: 2%;
padding-right: 2%;
padding-top: 15px;
margin: 5%;

background: #FFFFFF;
border: 0.5px solid #6A99AB;
box-shadow: 2px 10px 20px rgba(106, 153, 171, 0.25);
border-radius: 10px;
height:200px;
width: unset;
}


.truthboard-stack{
    display:flex;
    flex-direction: column;

}

@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {
    .body-visulization{
        position:relative;
        width:80%;
        height:auto;
        display: flex;
        flex-direction: row;
        text-align:'left';
        top: 150px;
        padding-bottom: 150px;

    }
    .body-decision{
        position:relative;
        top:30vh;
        width:90%;
        height:90vh;;
        left: 0px;
        display: flex;
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: row;
        justify-content: center;
        z-index: 0.99;
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;
        z-index:100;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
        z-index:100;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        min-height:600px;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}