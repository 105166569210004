@import url('https://unpkg.com/swiper/swiper-bundle.min.css');
.body-governance{
    top: 65px;
    position: relative;
    height:auto;
    padding-bottom: 65px;
    justify-content: left;
    align-items: center;
    vertical-align: middle;  
    text-align: center;
}
.works-governance{
    position: relative;
    height: auto;
    justify-content: left;
    align-items: center;
    vertical-align: middle;  
    text-align: center;
}
.tilt-90-button{

width: 100%;
height: 20%;
cursor: pointer;

}
.color-box-shade{
    /* Vector */
    width: 100%;
    height: 100%;
/* Vector */  

}
.governance-slides{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.service-button{
    width: 200px;
    max-width: 30%;
    height: 30px;
    backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
    text-align: center;
    float: center;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    padding:16px 14px;
    border: #000000;
    margin-top: 40px;
    border-style: solid;
    cursor: pointer;
    background:#000;

    }
    .service-button:hover{
        background: #242424 !important;
        color: #fff !important;

    }
    .tilt-90-button svg{
        cursor: pointer;

        fill: none;
        stroke: #000;
    }
    .tilt-90-button:hover svg{
        cursor: pointer;

        fill: #000;
        stroke: #000;
    }
    .tilt-90-button:hover svg path{
        fill: #fff;
        stroke: #fff;
    }
    .tilt-90-button svg path{
        fill: #000;
        stroke: #000;
    }

    .button-active{

    }
    .button-active svg{

        fill: #000;
        stroke: #000;

    }
    .button-active svg path{
        fill: #fff;
        stroke: #fff;
    }



.color-box-shade div{
    /* Vector */
    
position: relative;
}
.color-box-shade div img{
    width: 50px;
    height:10px;
    
}
.box-md-1 .color-box-shade img{
    /* Vector */
    position: relative;
    left:35%;
    top:-5%;
width: 70%;
height: 40%;


}
.box-md-3 .color-box-shade img {
    position: none;
    left: none;
    top: -5%;
    width: 30%;
    height: 15%;
}


section{
    position: relative;  
    background-color: #69666c;
    height: 450px;
    width: 100%;
    display: flex;
    align-items: center;
    
  }
.swiper{
    width: 90%;
  }
  
  .card{
    position: absolute;
    background: rgb(197, 67, 67);
    border-radius: 20px;
    height: 100%;
    width: 400px;
    margin: 20px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  
  .card::before{
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    background: white;
    border-radius: 20px 20px 0 0;
  }
  
  .card .card-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 100;
  }
  
  section .card .image{
    height: 140px;
    width: 140px;
    border-radius: 20%;
    padding: 3px;
    background: #e3e1e4;
    margin-top: 30px;
  }
  
  section .card .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20%;
    border: 3px solid #fff;
  }
  
  .card .media-icons{
    position: absolute;
    top: 12px;
    right: 95px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .card .media-icons i{
    color: #7d2ae8;
    opacity: 0.6;
    margin-top: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 10px;
  }
  
  .card .media-icons i:hover{
    opacity: 1;
  }
  
  .card .name-profession{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    color: black;
  } 
  
  .name-profession .name{
    font-size: 20px;
    font-weight: 600;
  }
  
  .name-profession .profession{
    font-size:15px;
    font-weight: 500;
  }
  
  .card .rating{
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
  
  .card .rating i{
    font-size: 18px;
    margin: 0 2px;
    color: #7d2ae8;
  }
  
  .card .button{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .card .button button{
    background: #7d2ae8;
    outline: none;
    border: none;
    color: #fff;
    padding: 8px 22px;
    border-radius: 10px;
    font-size: 14px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .button button:hover{
    background: #6616d0;
  }
  
  .swiper-pagination{
    position: absolute;
    top: 450px;
  }
  
  .swiper-pagination-bullet{
    height: 7px;
    width: 26px;
    border-radius: 25px;
    background: white;
  }
  
  .swiper-button-next, .swiper-button-prev{
    opacity: 0.7;
    color: white;
    transition: all 0.3s ease;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover{
    opacity: 1;
    color: white;
  }
  .security-display{
    width: 400px;
    max-width: 100%;
}
@media only screen and (max-width:700px) {
.truth-box{
    width: 100%!important;
}



}
@media only screen and (min-width:700px) {

    .body-governance{
        position: relative;
        top:70px;
        width:100%;
        height: 100vh;
        padding-bottom: 0px;

        display: flex;
        flex-direction: row;
    }
    .box-md-3 .color-box-shade img{
        /* Vector */
        position: relative;
        left:75%;
        top:-5%;
    width: 30%;
    height: 15%;
    
    
    }

    .works-governance{
        position: relative;
        top:70px;
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .governance-slides{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .tilt-90-button{
        position: relative;
        width: 15%;
        height: 100%;
        /* Security */

        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        text-align: center;
        }

    .works-background{
        position: relative;
        width: 100%;
        background-image: url("./resources/works-background.png");
        height:300px;
        margin-top: 100px;
    }


    .tilt-90-button div{
/* Security */



        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 141.3%;
        /* or 127px */
        letter-spacing: 0.04em;

        color: #000000;
        padding: 20px;
        transform: rotate(-90deg);
    }
    .tilt-90-button svg{
        /* Security */
        display: block;
        align:center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        padding-bottom: 100px;

                
            }
    .body-governance .display{
        width: 100%;
        height: 100%;
        /* Rectangle 407 */


background: rgba(106, 153, 171, 0.25);


    }

/* Vector */



/* Vector */

.security-box{
    box-sizing: border-box;

    background: #FFFFFF;
    border: 1px solid #B0B0B0;
    box-shadow: 2px 8px 30px 5px rgba(176, 176, 176, 0.25);
    border-radius: 20px;
    
}

  
/* Rectangle 429 */


}