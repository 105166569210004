.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}

.div-mob{
    width: 100%;
}

.box-prototyping{
    
    height: auto;
    padding: 25px;
    margin: 20px;
    display: inline-block;
    color:#FFFFFF;
    text-align: center;
    }
.logo-row{
    display: flex;
    flex-direction: row;
}
    .body-sd{
        position: relative;
        width:100%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
        top:105px;
    }
    .service-specific-text-prototyping{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        /* identical to box height, or 35px */
        text-align: center;
        
        color: #fff;
    }
    .row-box{
        text-align: center;  
        
    }
.body-prototype-service{
    /* Rectangle 456 */
    position: relative;
    height:auto;

background: #000000;
}
.div-50{
    width: 90%;
    padding: 5%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: center;
}

.checkMarkDiv{
    display:flex;
    text-align: left;
    padding-top: 10px;
}
.checkMarkDiv img{
    width:15px;
    padding-right: 15px;

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}

.div-50 h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;
display: flex;
align-items: flex-end;

color: #000000;


}




.div-50 p{

    padding-left: 0%;
    padding-right: 0%;
}
.div-50 h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 25%;
}


.bg-black::before {
    background-color: black;
    color:#fff;
  }
  ol {
    counter-reset: myOrderedListItemsCounter;
    
  }
  ol li {
    list-style-type: none;
    position: relative;
    padding: .4em;

  }
  ol li:before {
    counter-increment: myOrderedListItemsCounter;
    content: counter(myOrderedListItemsCounter)".";
    margin: .5em;
    border-radius: 50%;
    padding-right: .2em;
    padding-left: .2em;

  }
.bodyArchDesign{
    /* Rectangle 495 */

width: 100%;
height: auto;
background: linear-gradient(180deg, rgba(92, 134, 167, 0) 0%, rgba(92, 134, 167, 0.2) 100%);

}
@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {

    .body-sd{
        position: relative;
        width:100%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
        top:65px;
    }

    .div-50{
        width: 100%;
        padding: 0%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    }
    .div-50 p{

        padding-left: 0%;
        padding-right: 10%;
    }

    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;
    
    
    }

    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}