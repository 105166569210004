


.navigation {
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top:0px;
  padding: 5px 0px;
  background-color: #302d2d;
  color: #FFF;
  justify-content: center;
  align-items: center;
  
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}
.brand-name {
  text-decoration: none;
  color: #FFF;
  font-size: 1.3rem;
  justify-content: center;
    align-items: center;
}
.navigation-menu {
  justify-content: center;
  align-items: center;  
}
.navigation-menu ul {
  display: flex;
  padding: 0;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #FFF;
  justify-content: center;
    align-items: center;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #bfbaba;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #FFF;
}
@media screen and (max-width: 768px) {
  .navigation{
    position:fixed;
    z-index: 99;
  }
  .navigation-menu ul {
    position: absolute;
    top: 60px;  
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
    display: none;

  }
  .navigation-menu li {
    text-align: center;
    margin: 0;

  }
  .navigation-menu li a {
    color: black;
    width: 90%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .hamburger {
    display: block;
  }
}