.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}


.body-prototyping{
    position: relative;
    top:73px;
    width:100%;
    height:auto;
    display: flex;
    background-image: url('./resources/display-background.svg');
    flex-direction: column;
    vertical-align: middle;  
    text-align: center;
}

.mob-display{
    display: block;
}

.box-prototyping{
    
    height: auto;
    padding: 0px;
    margin: 0px;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    display: inline-block;
    color:#FFFFFF;
    text-align: center;
    }

    .body-works{
        position: relative;
        width:90%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .service-specific-text-prototyping{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        /* identical to box height, or 35px */
        text-align: center;
        
        color: #fff;
    }
    .row-box{
        text-align: center;  
        
    }
.body-prototype-service{
    /* Rectangle 456 */
    position: relative;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;

    height:auto;
    padding-top: 50px;
background: #000000;
}
.div-50{
    width: 100%;
    height: auto;
    text-align: left;

    vertical-align: left;  
    text-align: left;
}
.body-prototyping-bg{
    /* Rectangle 457 */
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    vertical-align: middle;  
    text-align: center;
    padding-bottom: 10%;
background: rgba(106, 153, 171, 0.1);

}
.display-block-overlay{
    width:0px;
    height: 0px;
    display: none;
    }

.checkMarkDiv{
    display:flex;
    text-align: center;
    padding-top: 10px;
}
.checkMarkDiv img{
    width:5px;
    padding-right: 15px;

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}

.div-50 h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;
display: flex;
align-items: flex-end;

color: #000000;


}
.rectangle-display{
    width: 90%;
    height: auto;
    padding: 5%;

}



.div-50 p{


}
.div-50 h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 25%;
}






.vir-button{
    /* Rectangle 443 */
    position: relative;
max-width: 300px;
cursor: pointer;
left:10%;
padding-top:10px;
margin-top: 50px;
padding-bottom:10px;
margin: 10px;
text-align: center;
/* Share Your Requirement */
background-color: #000;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.04em;
display: block;
color: #fff;


}

.vir-button:hover{

background-color: rgb(19, 19, 19);
}


@media only screen and (max-width:700px) {
    .truthboard-box{
        width: 90% !important;
    }
}

@media only screen and (min-width:700px) {
    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;
    
    
    }
    .box-prototyping{
    
        height: auto;
        padding: 55px;
        margin: 20px;
        width: 33.33%;
        margin-right: 60px;
        margin-left: 60px;
        display: inline-block;
        color:#FFFFFF;
        text-align: center;
        }
    .mob-display{
        display: none;
    }
    
    .rectangle-display{
        width: 50%;
        height: 100%;
        padding: 50px;
    
    }
    .div-50{
        width: 100%;
        padding: 0%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    }
    
    .display-block-overlay{


        width:initial;
        height: initial;
        display: block;

        }
    .body-prototyping-bg{
        /* Rectangle 457 */
        position: relative;
        width:100%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-bottom: 10%;
    background: rgba(106, 153, 171, 0.1);
    
    }

    .body-prototyping{
        position: relative;
        top:73px;
        width:100%;
        height:100vh;
        display: flex;
        background-image: url('./resources/display-background.svg');
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
    }
    .body-works{
        position: relative;
        width:90%;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}