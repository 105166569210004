.service-source{
    position: relative;
    top:62px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;  
    text-align: center;
}


.vir-active{
    color: #6A99AB;

}

.checkMarkDiv{
    display:flex;
    text-align: center;
    padding-top: 10px;
    margin-left:10% ;

}
.div-50-para{
    margin-left: 10%;
}
.checkMarkDiv img{
    width:15px;
    padding-right: 15px;
    

}
.div-50-img{
    width: 100%;
    text-align: left;

    vertical-align: center;  
    text-align: left;
}
.vir-list{
    position:relative;
    height:auto;
    width: 100%;
    bottom:0px;
    /* Linux and Windows */
    display: flex;
    flex-direction: row;
    margin-top: 5%;

    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    /* identical to box height */



}
.vir-list h3{
    padding: 10px;
    padding-top: 10px;
    color: rgba(106, 153, 171, 0.5);


}

.display-overlay{
position: relative;
top:15vh;
left:10%;
width:0px;
height: 0px;
}


    .truthboard-box{
        width: 90% !important;
    }

.display-virtualization-text{
    position: relative;
    /* Virtualization */
    text-align: center;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */

    color: #4A7E93;


}
.background-bg{


    width: 100%;
    height: auto;

}

.display-virtualization-para{
    position: relative;
    width:90%;
    padding: 5% ;
    /* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. */


font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 29px;


color: #000000;


    /* Virtualization */
text-align: center;
/* identical to box height */

}

    .body-virtualization{
        position: relative;
        top:75px;
        width:90%;
        display: flex;
        flex-direction: column;
        vertical-align: middle;  
        text-align: center;
        
    }

        .rectangle-display1{
            width: 100%;
            height: auto;
            
        }
        .rectangle-display1 h3{
            font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 18px;
margin: auto;
text-align: center;
padding-top: 50px;
/* identical to box height */
cursor: pointer;
color: #6A99AB;
        }
        .rectangle-display2{
            /* Rectangle 443 */
            width:90%;
            height: auto;
            padding: 5%;
            justify-content: center;
            align-items: center;
            vertical-align: middle;  
            text-align: center;
            background: rgba(106, 153, 171, 0.25);
        
        }

        .display-overlay-bg{

    
            width:0px;
            height: auto;
    
            }


@media only screen and (min-width:700px) {
  
    .body-virtualization{
        position: relative;
        top:75px;
        width:100%;
        height:100vh;
        display: flex;
        flex-direction: row;
        vertical-align: middle;  
        text-align: center;
    }
    .background-bg{

        opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        width: 0px;
        height: 0px;
        
    }
    .display-overlay{

        position: relative;

        width:40vw;
        height: auto;

        }
        .vir-list{
            position:absolute;
            width: 30%;;
            height:10%;
            bottom:15vh;
            display: flex;
            flex-direction: column;
            font-family: 'Red Hat Display';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            text-align: left;
    text-align: left;

/* identical to box height */

        }
        .vir-list h3{
            padding: 10px;
            /* Serverless */
        color: rgba(106, 153, 171, 0.5);

        text-align: left;

        
        }

        .display-overlay-bg{

    
            width:100%;
            height: auto;
    
            }
    .rectangle-display1{
        width: 30%;
        height: 100%;
        margin-top: 20px;
    }
    .rectangle-display2{
        /* Rectangle 443 */
        width:65%;
        height: 100%;
        padding: 0%;
        padding-right: 5%;

        justify-content: center;
        align-items: center;
        
        vertical-align: middle;  
        text-align: center;
        background: rgba(106, 153, 171, 0.25);
    
    }

    .display-virtualization-text{
        position: relative;
        top:20vh;
        /* Virtualization */
    text-align: right;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 61px;
    /* identical to box height */
    
    color: #4A7E93;

    }
    
    
    .display-virtualization-para{
        position: relative;
        top:20vh;
        width:60%;
        left:40%;
        padding: 0%;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    
    color: #000000;
    
    
        /* Virtualization */
    text-align: left;
    /* identical to box height */
    
    }
    .decision-board-heading{
        
        order: 2;
        width:50%;
        align-content: center;

    }
    .decision-board-text{
        width: 50%;
        height: auto;
        font-size:"4vw";
        top:20%;
    }

    .dashboard-nav{
        /* Rectangle 478 */
    

        
        width: 20%;
        height:auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    
        vertical-align: middle;
        border-radius: 0px 200px 0px 0px;

    
    }
    .body-dashboard{
        flex-direction: row;
    }
    .truthboard-box{
        width: unset;
        text-align:left;
        margin:10px;

        
    }
    .truthboard-stack{
        display:flex;
        margin-left: 5%;
        padding: 10px;
        width:90%;
        flex-direction: row;
    
    }


}