.heading-text-h3{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
}
a{
    text-decoration: none;
}
.para-text-h3{
    font-weight: 500;
    font-size: 16px;
}
.heading-text-h1{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 53px;
    padding: 10px;
    /* Help Every Digital Dream Come Alive */
}
.underline{
    text-decoration-line: underline !important;
    color: #5C86A7;

}
.display{
    display: block !important;

}
.heading-text-h2{
    
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 53px;
    margin:auto;
    text-align: left;
}

.text-paragraph{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
}
.underline{
    text-decoration: underline;
}
.row{
    position: relative;
    text-align: left;  
    display: inline-flex;
    flex-direction: row;
   
}

.box{
    
    height: auto;
    padding: 25px;
    margin: 25px;
    display: inline-block;
    box-shadow: 0px 8px 20px 8px rgba(96, 96, 96, 0.25);
    border-radius: 20px;
    }
.box-md-1{
    width:100%;
}
.box-md-3-5{
    width:35%;
}
.box-md-2{
    width:50%
}
.box-md-2-5{
    width:65%
}
.box-md-3{
    width:33%
}
.box-md-4{
    width:25%
}
.box-md-5{
    width:20%;
}

.box-md-7{
    width:14.3%;


}
.text-center{
    text-align: center;  

}
.text-left{
    text-align: left;
}
.white-background{
    background: #fff;
}
.fade-in {
    animation: fadeIn 3s forwards;
  }
.div-30{
    width: 30%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-40{
    width: 40%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-50{
    width: 100%;
    height: auto;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-60{
    width: 70%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-70{
    width: 70%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;


}
.div-33{
    width: 33.33%;
    height: 100%;
    text-align: left;

    vertical-align: left;  
    text-align: left;
}

.div-num h2{
    /* Open Source Services */

position: relative;

font-family: 'Red Hat Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;


color: #000000;



}
.div-num h3{
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;

}
.div-num h1{
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;

}
.div-num p{
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;

}
.div-num ul{
    padding-left: 15%;
    padding-right: 10%;
    text-align: left;
}

.div-num h2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
}
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }


  /*Collapsible*/
  .collapsible {
    background-color: #F9F9F9;
    color: #000;
    cursor: pointer;
    padding-bottom: 18px;
    padding-top: 18px;
    padding-right:5%;
    width: 90%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-top: 1px solid #E6E6E6;
    vertical-align: middle;
  }

  .active {
    border-top: 3px solid #455A64;
  }
  
  .collapsible:hover {
    background-color: rgb(235, 231, 231);
    border-top: 2px solid #455A64;

  }

  .content {
    max-height: 0;
    width: 90%;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .expand{
    content:url("./resources/up_list.svg");
    }
    .collapse{
        content:url("./resources/down_list.svg");
    }



      .owl-theme .owl-nav [class*='owl-']{
        background: none !important;
      }
      .owl-next:focus, .owl-prev:focus {outline: none;}
    @media only screen and (min-width:700px) {
        .display{
        
            width: 100%;
        }
        .box-md-6{
            width:16.7%;
            padding: 2%;
            margin: 2%;
        }
    }
    .empower{
        text-align: left; 
        width: 50%;
    }
    .owl-theme{
        width:70%;
        overflow: hidden;
        outline: none;
      }
      .active{
        border-top:none
      }
      .body-empower{
        position: relative;
        height:auto;
        padding-top: 80px;
        background: #F9F9F9;
        padding-bottom: 50px;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
    
        flex-direction: row;
        width: 100%;
    }

@media only screen and (max-width:700px) {

    .div-50{
        width: 50%;
        height: 100%;
        text-align: left;
        vertical-align: left;  
        text-align: left;
    }
    .div-60{
        width: 90%;
        height: 100%;
        text-align: left;
    
        vertical-align: left;  
        text-align: left;
    
    
    }

    .heading-text-h2{
        
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        margin: auto;
        text-align: center;
        /* or 91px */
        
        
        }
        .row{
            text-align: center;  
            display: flex;
            flex-direction: column;
           
        }
        .tools-row{
            text-align: center;  
            display: inline-block;
            flex-direction: row;

        }
        .box{
            width: 80%;
            margin: 5%;
            
            padding: 5%;
        }
        .box-md-6{
            width:45%;
            padding: 2%;
            margin: 2%;
        }
        .empower{
            text-align: left; 
            width: 100%;
        }
        .owl-theme{
            width:100%;
            overflow: hidden;
          }
          .body-empower{
            position: relative;
            height:auto;
            padding-top: 80px;
            background: #F9F9F9;
            padding-bottom: 50px;
            padding-left: 0%;
            padding-right: 0%;
            display: flex;
        
            flex-direction: column;
            width: 100%;
        }
}


